const AEDInstructionData = [

  {
    step: 1,
    title: "Preconditions for Using an AED: ",
    description: "1. The person is not breathing.\n2. Call 112.\n 3. CPR was started\n If necessary, go to the CPR instruction using the CPR button",

  },
  {
    step: 2,
    title: "Turn on AED",
    description: "Follow the Voice Prompts",
    url: "../../images/animationAED/gif-1.gif"
  },
  {
    step: 3,
    title: "Remove Person's Clothing to Expose Chest Area",
    description: "Ensure that the victim’s chest is bare and dry.",
    url: "../../images/animationAED/gif-2.gif"
  },
  {
    step: 4,
    title: "Attach AED Pads",
    description: "Peel off the backing of each pad.\nPlace one pad on the upper right side of the chest. Place the other pad on the lower left side of the chest.",
    url: "../../images/animationAED/gif-3.gif"

  },
  {
    step: 5,
    title: "Let the AED Analyze the Heart's Rhythm",
    description: "At this stage, wait until the AED has completed its analysis.\nDo not touch the victim when the AED is analyzing the heart rate.",
    url: "../../images/animationAED/gif-4.gif"
  },
  {
     step: 6,
     title: "If Shock is Advised, Stand Clear and Press 'Shock'",
     description: "Do not touch the victim while the AED is delivering a shock.\nThe AED may also determine that a shock is not needed. In that case, skip to Step 7.",
     url: "../../images/animationAED/gif-5.gif"
  },
  {
     step: 7,
     title: "Continue CPR",
     description: "After the AED delivers the shock, resume series of 30 compression and 2 breaths.\nContinue to follow the defibrillator's instruction until patient gains consciousness or until medical services arrive.",
     url: "../../images/animationAED/gif-6.gif"
   },

];

export default AEDInstructionData;
