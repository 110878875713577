const CPRInstructionData = [
    {
      step: 1,
      title: "Recognize Cardiac Arrest",
      description: "Look for signs such as the victim losing consciousness, falling, and not reacting to stimuli.",
      url: "../../images/animations/gif1.gif"
    },
    {
      step: 2,
      title: "Check Breathing",
      description: "Check if the victim's breathing is non-existent or very irregular.",
      url: "../../../images/animations/gif2.gif"
    },
    {
      step: 3,
      title: "Call Emergency Services",
      description: "Dial 112. Describe the situation, give the nearest address, tell what has been done, and don't end the call until the operator instructs you to do so.",
      url: "../../../images/animations/gif7.gif"
    },
    {
      step: 4,
      title: "Find a Hard Surface",
      description: "Place the victim on a hard surface.",
      url: "../../images/animations/gif1.gif"
    },
    {
      step: 5,
      title: "Place Your Hands",
      description: "Kneel beside them, and place your hands one on top of the other in the middle of the chest. ",
      url: "../../images/animations/gif5.gif"
    },
    {
      step: 6,
      title: "Start Compressions",
      description: "Press with your whole body weight, performing 30 strong compressions at a steady pace, ",
      url: "../../images/animations/gif3.gif"
    },
    {
      step: 7,
      title: "Breaths",
      description: "Two breaths can be performed every 30 pressures. Then resume cardiac massage: series of 30 pressures.",
      url: "../../images/animations/gif6.gif"
    },
    // {
    //   step: 8,
    //   title: "Using the Defibilator",
    //   description: "-Turn on the defibrillator and read the instructions while someone else performs CPR.\n-Start by placing the electrodes on the skin of the victim and check that no one is touching them while the defibrillator analyses their heart rate.\n-If an electric shock must be delivered, ensure that everyone is standing far from the victim and its immediate environment.\n-Press the button if required, and it will deliver the electric shock by itself.\n",

    // },
    // {
    //   step: 9,
    //   title: "Using the Defibilator",
    //   description: "-If the defibrillator asks you, perform CPR by series of 30 compression and two breaths if you know how to perform mouth-to-mouth.\n-Continue to follow the defibrillator's instruction until the victim regains normal breathing or the emergency services arrive.\n-If the victim's breathing goes back to normal and she wakes up, stop doing CPR BUT don't turn off the defibrillator and keep the electrodes on the victim.\n-If the victims stay unsconcious after regaining normal breathing, put them in lateral position of safety and wait for the emergency services.",

    // },
    // {
    //   step: 8,
    //   title: "Turn on AED",
    //   description: "Follow the Voice Prompts ",
    //   url: "#"
    // },
    // {
    //   step: 9,
    //   title: "Remove the victim's clothing from the chest area.",
    //   description: "Make sure the victim's chest is dry. \nIf necessary, use a towel or tissue to wipe away any excess moisture where the pads will be placed.”",
    //   url: "#"
    // },
    // {
    //   step: 10,
    //   title: "Attach AED pads",
    //   description: "Remove the peel from each pad. Place one pad on the upper right breast.\n Place the other pad on the lower left breast.",

    // },
    // {
    //   step: 11,
    //   title: "Stand clear and let the AED analyze the heart's rhythm.",
    //   description: "Wait for the AED to complete the analysis.\n Do not touch the victim when the AED is analyzing the heart rate."
    // },
    // {
    //    step: 12,
    //    title: "If shock is advised, stand clear and press 'SHOCK' button",
    //    description: "Press the 'SHOCK' button and it will deliver the electric shock by itself.\n AED may also determine that a shock is not required. In this case, skip step 13."
    // },
    // {
    //    step: 13,
    //    title: "Continue CPR",
    //    description: "After the AED delivers the shock, resume chest compressions for 2 minutes at a rate of 100 per minute.\n Continue to follow the defibrillator's instruction until victim gains consciousness or until medical services arrive."
    //  },

];

export default CPRInstructionData;
